export default {
    data: () => ({
        errors: {},
        credentials: {}
    }),

    watch: {
        "credentials.email": {
            handler: function (newValue, oldValue) {
                if(oldValue){
                    this.validateFormat('email', 'email');
                }
            },
            immediate: true
        },
        "credentials.password": {
            handler: function (newValue, oldValue) {
                if(oldValue){
                    this.validateField('password');
                }
            },
            immediate: true
        }
    },


    methods: {
        validateField(field) {
            // Delete property of errors if exists
            delete this.errors[field];
            // Restore reactivity on object
            this.errors = Object.assign({}, this.errors);
            if(!this.credentials[field]){
                // For all inputs
                this.errors[field] = 'Este campo es requerido';
                this.errors = JSON.parse(JSON.stringify(this.errors));
            }
        },
        validateFormat(format, field) {
            let regex = "";

            if (format === 'email') {
                regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            }
            // Restore reactivity on object
            delete this.errors[field];

            if(!this.credentials[field]){
                this.errors[field] = 'Este campo es requerido';
                this.errors = JSON.parse(JSON.stringify(this.errors));
            }else{
                if( regex && !regex.test(String(this.credentials[field]).toLowerCase()) ){
                    this.errors[field] = 'El formato no es válido';
                    this.errors = JSON.parse(JSON.stringify(this.errors));
                }
            }
        }
    }
}